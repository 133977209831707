<template>
  <div class="m-input" :class="inputClass">
    <div
      v-if="!!$slots.default"
      class="flex items-center "
      :class="[
        label ? 'justify-between' : 'justify-end',
        (inputClass || '').includes('sm') ? 'mb-1' : 'mb-2',
      ]"
    >
      <label v-if="label" :for="id">{{ label }}</label>
      <slot />
    </div>
    <label v-if="!$slots.default && label" :for="id">{{ label }}</label>
    <div class="w-full relative group">
      <input
        :id="id"
        :class="{ uppercase: textUppercase }"
        v-bind="$attrs"
        @keydown="$emit('keydown')"
        @keyup="$emit('keyup')"
        @keypress="$emit('keypress')"
        @change="$emit('change')"
        :value="modelValue"
        @input="$emit('update:modelValue', $event.target.value)"
        spellcheck="false"
        autocomplete="off"
      />
      <slot name="extra" />
    </div>
  </div>
</template>

<script>
export default {
  name: "Input",
  inheritAttrs: false,
  props: {
    modelValue: {
      type: String,
      default: "",
      required: true,
    },
    inputClass: String,
    label: String,
    textUppercase: Boolean,
    padding: String,
    id: String,
  },
};
</script>
<style lang="scss" scoped>
.m-input {
  @apply w-full flex flex-col;
  input {
    @apply bg-white text-sm appearance-none block w-full leading-snug px-5 py-2.5 rounded text-brand-secondary border-2 border-brand-ash border-opacity-60 transition group-hover:border-theme-primary hover:border-theme-primary focus:outline-none focus:ring-transparent placeholder-gray-300;

    @include inputPlaceholder() {
      @apply text-sm text-brand-body leading-snug;
    }
    @include resetAutoComplete() {
      @apply text-sm text-brand-body leading-snug bg-white;
    }

    &:-webkit-autofill::first-line {
      @apply font-default;
    }

    &:disabled {
      @apply border bg-brand-body bg-opacity-5 cursor-default;
      &:hover {
        @apply border-brand-ash border-opacity-60;
      }
    }
  }

  label {
    @apply flex items-center text-base font-normal relative leading-none text-brand-secondary;
  }

  > label {
    @apply mb-2;
  }

  &.sm {
    label {
      @apply text-sm leading-none;
    }
    input {
      @apply leading-none px-4;
      height: 2.8rem !important;
    }
  }

  &.error {
    label {
      @apply text-red-400;
      animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
      transform: translate3d(0, 0, 0);
      backface-visibility: hidden;
      perspective: 1000px;
    }
    input {
      @apply border-red-400;
    }
  }

  @keyframes shake {
    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }
}
</style>

<style scoped>
::-webkit-input-placeholder {
  text-transform: initial;
}

:-moz-placeholder {
  text-transform: initial;
}

::-moz-placeholder {
  text-transform: initial;
}

:-ms-input-placeholder {
  text-transform: initial;
}
</style>
