<template>
  <button
    class="m-button"
    :type="type"
    :id="id"
    :disabled="loader || disabled"
    @click="$emit('click')"
    :class="[{ outlined: outline }, inline ? '' : 'w-full']"
  >
    <span class="m-button-icon" v-if="icon || loader">
      <svg
        class="animate-spin w-5 h-5"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        v-if="loader"
      >
        <circle
          class="opacity-25"
          cx="12"
          cy="12"
          r="10"
          stroke="currentColor"
          stroke-width="3"
        ></circle>
        <path
          class="opacity-75"
          fill="currentColor"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
        ></path>
      </svg>
      <Icon :name="icon" v-else-if="icon" :class="iconClass" />
    </span>
    <span :class="{ inline: inline }">{{ title }}</span>
  </button>
</template>

<script>
export default {
  name: "Button",
  props: {
    type: {
      type: String,
      default: "text",
    },
    loader: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    outline: {
      type: Boolean,
      default: false,
    },
    inline: {
      type: Boolean,
      default: false,
    },
    title: String,
    icon: String,
    id: String,
    iconClass: String,
  },
};
</script>
<style lang="scss" scoped>
.m-button {
  @apply flex font-light items-center font-default px-6 py-4 text-base rounded-md relative  outline-none focus:outline-none transition leading-none focus:ring-transparent;

  &:not(.outlined) {
    @apply bg-theme-secondary hover:bg-theme-secondary hover:bg-opacity-95 text-white cursor-pointer border-none;
  }

  &.outlined {
    @apply bg-transparent border border-theme-light-shade text-theme-body font-medium;

    &.dark {
      @apply border-theme-secondary text-theme-secondary;
    }
  }

  &.no-bg {
    @apply bg-transparent border-transparent text-theme-body font-normal hover:bg-transparent hover:text-theme-dark;
  }

  &.red-bg {
    background: rgba(251, 69, 123, 0.1);
    color: #ab304d;
    @apply font-medium;

    &:hover {
      background: rgba(251, 69, 123, 0.15);
    }
  }

  span:not(.m-button-icon) {
    @apply flex-grow text-center -ml-4 pl-4;
    &.inline {
      @apply px-3;
    }
  }

  .m-button-icon {
    svg {
      @apply w-6 h-6 -my-2 mr-3;

      &.xsmall {
        @apply w-4 h-4;
      }

      &.small {
        @apply w-5 h-5;
      }

      &.large {
        @apply w-7 h-7;
      }
    }
  }

  &.medium {
    @apply py-3 text-md;
  }

  &.medium-large {
    @apply py-3.5 text-md;
  }

  &.small {
    @apply py-2 text-sm;
  }

  &.xsmall {
    @apply py-2 text-xs;
  }

  &:disabled {
    @apply opacity-70 cursor-default;
  }

  &.responsive {
    @apply w-32 md:w-auto px-4 py-2 md:px-6 md:py-3 text-sm md:text-base;

    svg {
      @apply w-4 h-4 md:w-6 md:h-6;
    }
  }
}
</style>
