<template>
  <div class="flex items-center justify-between">
    <!-- Title -->
    <div class="flex items-start">
      <span
        v-if="checkType == 'warning'"
        :class="title && title.length > 30 ? 'mt-0.5' : ''"
      >
        <Icon name="document-warning" class="flex w-5 h-5 flex-shrink-0" />
      </span>
      <span
        v-else-if="['success', 'approved'].includes(checkType)"
        :class="title && title.length > 30 ? 'mt-0.5' : ''"
        class="w-5 h-5 border border-green-400 flex items-center justify-center bg-green-400 text-white rounded-full flex-shrink-0"
      >
        <CheckIcon class="w-3.5 h-3.5" />
      </span>
      <span
        v-else
        class="flex items-center justify-center w-5 h-5 border bg-theme-bg border-theme-bg-shade text-theme-body rounded-full flex-shrink-0"
        :class="title && title.length > 30 ? 'mt-0.5' : ''"
        ><UploadIcon class="w-3 h-3"
      /></span>
      <p
        class="text-md text-theme-secondary text-left px-3.5 flex-grow max-w-sm"
      >
        {{ title }}
      </p>
    </div>
    <div class="flex flex-col justify-end flex-shrink-0">
      <div class="flex items-center justify-end space-x-5 flex-shrink-0">
        <slot />
      </div>
      <slot name="msg"></slot>
    </div>
  </div>
</template>

<script>
import { CheckIcon, UploadIcon } from "@heroicons/vue/solid";
export default {
  name: "StaticCheckList",
  props: {
    title: String,
    checkType: String,
    msg: String,
  },
  components: {
    CheckIcon,
    UploadIcon,
  },
};
</script>
