import { createApp } from "vue";
import App from "./App.vue";
import "@/assets/styles/styles.scss";
import router from "./router";
import axios from "./plugins/axios";
import axiosAlgolia from "./plugins/axiosAlgolia";
import Icon from "@/components/Icon.vue";
import PlusMinusInput from "@/components/PlusMinusInput.vue";
import Button from "@/components/Button.vue";
import Badge from "@/components/Badge.vue";
import Input from "@/components/Input.vue";
import Card from "@/components/Card.vue";
import Modal from "@/components/Modal.vue";
import ModalFull from "@/components/ModalFull.vue";
import Spinner from "./components/Spinner";
import Sorting from "@/components/sorting/Sorting.vue";
import SortingMobile from "@/components/sorting/SortingMobile.vue";
import FilterBadge from "@/components/FilterBadge.vue";
import Tooltip from "@/components/Tooltip.vue";
import Toast from "@/components/Toast.vue";
import Confirm from "@/components/Confirm.vue";
import BlurryWrap from "@/components/BlurryWrap.vue";
import StaticCheckList from "@/components/StaticCheckList.vue";

import Auth from "./modules/Auth";
import { store } from "./store";
import InstantSearch from "vue-instantsearch/vue3/es";
import VueTippy from "vue-tippy";
import "tippy.js/dist/tippy.css";
import Maska from "maska";
import { SnackbarService, Vue3Snackbar } from "vue3-snackbar";
import "vue3-snackbar/dist/style.css";
import VueSignaturePad from "vue-signature-pad";

const { isLoggedIn } = Auth();

const app = createApp(App);

app.use(InstantSearch);
app.use(store);
app.use(router);
app.use(Maska);
app.use(SnackbarService);
app.use(VueSignaturePad);

app.component("Icon", Icon);
app.component("Button", Button);
app.component("Badge", Badge);
app.component("Input", Input);
app.component("Modal", Modal);
app.component("ModalFull", ModalFull);
app.component("Confirm", Confirm);

app.component("Card", Card);
app.component("PlusMinusInput", PlusMinusInput);
app.component("Sorting", Sorting);
app.component("SortingMobile", SortingMobile);
app.component("Tooltip", Tooltip);
app.component("Toast", Toast);
app.component("FilterBadge", FilterBadge);
app.component("Spinner", Spinner);
app.component("BlurryWrap", BlurryWrap);
app.component("vue3-snackbar", Vue3Snackbar);
app.component("StaticCheckList", StaticCheckList);

window.moment = require("moment");

app.use(VueTippy, {
  directive: "tippy", // => v-tippy
  component: "tippy", // => <tippy/>
  componentSingleton: "tippy-singleton", // => <tippy-singleton/>,
  defaultProps: {
    placement: "top",
    allowHTML: true,
    arrow: true,
    maxWidth: 320,
  },
});

const api = {
  api: axios.api,
  authApi: axios.authApi,
  isLoggedIn: isLoggedIn(),
};
app.provide("axios", api);

const algoliaApi = {
  api: axiosAlgolia.api,
};
app.provide("axiosAlgolia", algoliaApi);

app.mount("#app");
