import { inject, onMounted, onUnmounted, reactive } from "vue";
import { useRoute } from "vue-router";
import { store } from "../store";

export default function Brands(limit = 0) {
  const axios = inject("axios");
  const route = useRoute();

  const brands = reactive({
    loading: false,
    data: [],
    limitData: [],
    error: "",
  });
  const getbrands = (val = null) => {
    let filter = val ? val.value.map((c) => c.slug) : null;
    brands.loading = true;
    axios.api
      .get("/brands", {
        params: {
          category: filter,
        },
      })
      .then((resp) => {
        if (resp.status) {
          let data = resp.data.data;
          if (store.getters.cart.price_list_id == "33") {
            brands.data = data;
          } else {
            brands.data = data.filter((e) => !e.name.includes("GGG"));
          }
        } else {
          brands.error = resp.data.error;
        }
      })
      .catch(function(error) {
        console.log(error);
      })
      .finally(() => (brands.loading = false));
  };

  const newBrands = reactive({
    loading: false,
    data: [],
    limitData: [],
    error: "",
  });

  const getNewBrands = () => {
    newBrands.loading = true;
    axios.api
      .get("/newBrands")
      .then((resp) => {
        if (resp.status) {
          newBrands.data = resp.data.data;
          newBrands.limitData = resp.data.data.slice(0, limit);
        } else {
          newBrands.error = resp.data.error;
        }
      })
      .catch(function(error) {
        console.log(error);
      })
      .finally(() => (newBrands.loading = false));
  };
  const banner = reactive({
    loading: false,
    data: [],
    name: "",
    image: "",
    error: "",
  });

  const getBanner = async () => {
    banner.loading = true;
    axios.api
      .get("/collectionDetails?brand_slug=" + route.params.slug)
      .then((resp) => {
        if (resp.status) {
          banner.data = resp.data.data;
        } else {
          banner.error = resp.data.error;
        }
      })
      .catch(function(error) {
        console.log(error);
      })
      .finally(() => (banner.loading = false));
  };

  var page = 1;
  let sort = "";
  let stock = false;

  const brandData = reactive({
    loading: false,
    data: [],
    error: "",
    onScrollLoading: false,
  });

  async function getData(load) {
    let d = {};
    const category = store.getters.getSelected.map((r) => r.id);
    const price = store.getters.getPriceCheck ? store.getters.getPriceRange : 0;
    let dataToPost = {
      page,
      stock,
      sort,
      category,
      min: 0,
      max: price,
    };
    brandData[load] = true;
    if (axios.isLoggedIn) {
      await axios.authApi
        .get("/aCollection?brand_slug=" + route.params.slug, {
          params: dataToPost,
        })
        .then((res) => {
          d = res.data.data;
          if (res.data.filterOptions.length > 0)
            store.dispatch("updateOptions", res.data.filterOptions);
        });
    } else {
      await axios.api
        .get("/collection?brand_slug=" + route.params.slug, {
          params: dataToPost,
        })
        .then((res) => {
          d = res.data.data;
          if (res.data.filterOptions)
            store.dispatch("updateOptions", res.data.filterOptions.filter(i => !i.login_required));
        });
    }
    brandData[load] = false;
    return d;
  }

  const reset = () => {
    brandData.data = [];
    page = 1;
  };
  async function onSortChanged(v) {
    reset();
    sort = v;
    brandData.data = await getData("loading");
  }

  async function onStockChanged(v) {
    reset();
    stock = v;
    maxPrice(stock);
    store.dispatch("updateOptions", []);
    store.dispatch("removeOption", "clear");
    // onFilterChanged();
  }

  async function onFilterChanged(v) {
    reset();
    const category = store.getters.getSelected.map((r) => r.id);
    maxPrice(stock, category);
    brandData.data = await getData("loading");
  }

  async function onPriceRangeChanged(v) {
    reset();
    brandData.data = await getData("loading");
    // store.dispatch("removeOption",'clear');
  }

  async function maxPrice(stock = false, category = []) {
    await axios.authApi
      .get("/maxPrice?brand_slug=" + route.params.slug, {
        params: {
          stock: stock,
          category: category,
        },
      })
      .then((res) => {
        if (res.data.status) {
          store.dispatch("setPriceRange", Number(res.data.data.max_price));
          store.dispatch("setMaxPrice", Number(res.data.data.max_price));
          store.dispatch("setPriceCheck", false);
        }
      });
  }

  async function handleScroll() {
    const itemsContainer = document.querySelector(".items-listing-container");
    if (!itemsContainer) {
      return;
    }
    const canLoadMore = page < brandData.data.last_page;
    const elemScrollHeight = itemsContainer.scrollHeight;
    const elemScrollLoadHeight = elemScrollHeight - elemScrollHeight * 0.5;
    const shouldLoadMore =
      window.pageYOffset + 200 > elemScrollLoadHeight ||
      elemScrollHeight < window.innerHeight ||
      window.pageYOffset + elemScrollLoadHeight < window.innerHeight;

    if (canLoadMore && shouldLoadMore && !brandData.isLoadingScrollData) {
      page++;
      brandData.isLoadingScrollData = true;
      const res = await getData("onScrollLoading");
      brandData.data.current_page = page;
      brandData.data.data?.push(...res.data);
      brandData.isLoadingScrollData = false;
    }
  }

  // this will register the event when the component is mounted on the DOM
  onMounted(() => window.addEventListener("scroll", handleScroll));

  // We then unregister the listener when the component is removed from the DOM
  onUnmounted(() => window.removeEventListener("scroll", handleScroll));

  return {
    isLoggedIn: axios.isLoggedIn,
    newBrands,
    getNewBrands,
    brands,
    getbrands,
    banner,
    getBanner,
    brandData,
    getData,
    onSortChanged,
    onStockChanged,
    onFilterChanged,
    onPriceRangeChanged,
    maxPrice,
  };
}
