<template>
  <Listbox v-model="selectedOption" :disabled="!isLoggedIn">
    <div class="filter-listing">
      <ListboxLabel class="filter-listing-label">
        <Icon name="sort" />
        <span>Sort By</span>
      </ListboxLabel>
      <ListboxButton
        class="filter-listing-btn"
        :class="!isLoggedIn ? 'disabled' : ''"
      >
        <span
          class="absolute inset-y-0 left-0 flex items-center pl-2 pointer-events-none"
          v-if="!isLoggedIn"
        >
          <Icon
            name="lock-filled"
            class="w-4 h-4 text-white"
            aria-hidden="true"
          />
        </span>
        <span class="block truncate" :class="!isLoggedIn ? 'pl-5' : ''">{{
          selectedOption.name
        }}</span>
        <span
          class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none"
        >
          <SelectorIcon class="w-5 h-5 text-white" aria-hidden="true" />
        </span>
      </ListboxButton>

      <transition
        leave-active-class="transition duration-100 ease-in"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <ListboxOptions class="filter-listing-container">
          <ListboxOption
            v-slot="{ active, selected }"
            v-for="opt in optionsList"
            :key="opt.name"
            :value="opt"
            as="template"
          >
            <li :class="[selected || active ? 'active' : '']">
              <p
                :class="[
                  selected ? 'font-medium' : 'font-normal',
                  'block truncate',
                ]"
              >
                {{ opt.name }}
              </p>
              <span v-if="opt.description">{{ opt.description }}</span>
              <span v-if="selected" class="check-mark">
                <CheckIcon aria-hidden="true" />
              </span>
            </li>
          </ListboxOption>
        </ListboxOptions>
      </transition>
    </div>
  </Listbox>
</template>

<script>
import { onMounted, onUnmounted, ref, watch } from "vue";
import Brands from "@/modules/Brands";
import {
  Listbox,
  ListboxLabel,
  ListboxButton,
  ListboxOptions,
  ListboxOption,
} from "@headlessui/vue";
import { CheckIcon, SelectorIcon } from "@heroicons/vue/solid";
import { useStore } from "vuex";
const defaultOptions = [
  {
    name: "Newest Products",
    value: "latest",
    description:
      '"New Products" are products that are the newest in our catalog',
  },
  {
    name: "Most Popular",
    value: "popularity",
    description:
      '"Most Popular" are products that the most shops are purchasing',
  },
  {
    name: "Best Selling",
    value: "best-selling",
    description:
      '"Best Selling" are products with the highest quantity of sales',
  },
  {
    name: "Alphabetical Sort",
    value: "name",
    description: "Products sorted from A to Z",
  },
  {
    name: "Lowest Price",
    value: "price-low",
    description: "Products sorted by the lowest price first",
  },
  {
    name: "Highest Price",
    value: "price-high",
    description: "Products sorted by the highest price first",
  },
];

export default {
  components: {
    Listbox,
    ListboxLabel,
    ListboxButton,
    ListboxOptions,
    ListboxOption,
    CheckIcon,
    SelectorIcon,
  },
  props: {
    options: {
      type: Array,
      default: () => defaultOptions,
    },
  },
  emits: ["onSort"],
  setup(props, { emit }) {
    const store = useStore();
    const selectedOption = ref(props.options[0]);
    const optionsList = ref([]);

    onMounted(() => {
      optionsList.value = props.options;
      let i = store.getters.getIsRank ? 1 : 0;
      if (store.getters.getIsRank) selectedOption.value = optionsList.value[i];
    });
    onUnmounted(() => {
      store.dispatch("setPriceRange", 499);
      store.dispatch("setMaxPrice", 500);
      store.dispatch("setPriceCheck", false);
      store.dispatch("setIsRank", false);
      store.dispatch("setPriceRange", 0);
      store.dispatch("setPriceCheck", false);
      store.dispatch("setMaxPrice", 0);
    });

    watch(selectedOption, (current) => {
      if (current.value) {
        store.dispatch("setSortValue", current.value);
        emit("onSort", current.value);
      }
    });
    const { isLoggedIn } = Brands();
    return {
      selectedOption,
      optionsList,
      isLoggedIn,
    };
  },
};
</script>

<style lang="scss" scoped>
.filter-listing {
  @apply md:flex relative hidden items-center space-x-4 flex-shrink-0;
}
.filter-listing-container {
  @apply absolute top-full right-0 mt-1 overflow-auto text-base bg-white rounded shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm z-50 divide-y divide-gray-100;
  min-width: 200px;
  max-height: 275px;
  @include CssScrollbar();

  li {
    @apply flex flex-col select-none relative py-3 pr-10 pl-4 text-brand-primary cursor-pointer;
    &.active {
      @apply text-brand-primary bg-brand-smoke;
    }
    p {
      @apply text-md;
    }
    span {
      @apply text-xs  text-theme-body font-normal;
      &.check-mark {
        @apply absolute inset-y-0 right-0 flex items-start pt-3 justify-center pr-3 text-brand;
        svg {
          @apply w-5 h-5;
        }
      }
    }
  }
}
.filter-listing-btn {
  @apply relative py-2 pl-4 pr-8 text-left font-normal bg-black bg-opacity-50 rounded cursor-pointer focus:outline-none text-xs sm:text-sm ring-1 ring-white ring-opacity-20;
  min-width: 200px;

  &.disabled {
    @apply cursor-default;
  }
}
.filter-listing-label {
  @apply flex items-center text-xs md:text-sm font-light uppercase tracking-wide;
  svg {
    @apply w-5 h-5 mr-2;
  }
}
</style>
